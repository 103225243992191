.imgRedonda {
  width: 300px;
  height: 300px;
  border-radius: 150px;
}

#circulo {
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  background: #2d4e82;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
#circulo > p {
  font-family: sans-serif;
  color: #ffffff;
  font-size: 1rem;
  font-weight: bold;
}

a > .text-white {
  color: #ffffff;
}

.btn-img-profile {
  background-color: transparent !important;
  border-color: transparent !important;
}

.css-15k3avv {
  z-index: 1000 !important;
}
