.width-select{
    width: 380px;
};

.btn-right{
    float: right;
};


.padding-0{
    padding-right: 0px !important;
    padding-left: 0px !important;
}