.width-select{
    width: 85% !important;
}

.buttons-edit{
    color: green !important;
    font-size: large !important;
}

.buttons-delete{
    color: red !important;
    font-size: large !important;
}

.padding-left0{
    padding-left: 0px !important;
    padding-right: 0px !important;
}