fieldset {
  padding-bottom: 20px;
  border-bottom: 2.5px inset #c5c5c5 !important;
  margin-bottom: 20px;
}

.c-label {
  padding-top: 7px;
  padding-bottom: 7px;
  margin-bottom: 0px;
  color: #353535;
}
.p-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  line-height: 1.52857;
}

.content-logo-center {
  border-image: initial;
  height: 120px;
  display: flex;
  align-items: center;
  border-bottom: none;
  border-radius: 4px 4px 0px 0px;
  justify-content: center;
}

.picture-app-client {
  height: 60px;
  display: inline;
  max-width: 100%;
}

.cursor-content {
  cursor: pointer;
}

.is-invalid-other {
  border-color: #f05050 !important;
}

@media (max-width: 433px) {
  .mpage-sm-2 {
    margin-top: 0.5rem !important;
  }
}
