.logo-position{
    margin-bottom: 80px;
}

.letter-white{
    color: #EDF2EB;
}

.card-colour{
    background-color: #784691 !important;
}

.body-content{
    position: relative;
    top: 100%;
}

.size-icon-message{
    font-size: 30px;    
}