.border-leftcolor{
    border-left-color: lightsteelblue !important;
}

.height-100{
    height: 100% !important;
}

.height-0{
    height: '-webkit-fill-available' !important;
}

.scroll-message{
    width: 100%;
    height: 393px;
    overflow-y: scroll
}

.padding-top40{
    padding-top: 40%;
}

.padding-left45{
    padding-left: 45%;
}

.padding-left50{
    padding-left: 50%;
}

.padding-left23{
    padding-left: 23%;
}

.selectd-all{
    width: 30px;
    height: 30px;
}

.checkbtn:active{
    background: #5d9cec !important;
}

.checkbtndelete:active{
    background: red !important;
}

.colour-icon{
    background-color: purple !important;
}

.position-icon{
    color: white;
    margin-bottom: 0px;
    height: 100%;
    padding-top: 11px;
    text-align: center;
}

.with-icon39{
    width: 39px;
}

.point{
    cursor: pointer;
}

.NotMessage{
    margin-top: 29% !important;
    text-align: center;
}

