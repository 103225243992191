.bg-fount{
    background-color: #F5F7FA;
}

.btn-principal{
    background: #698ED1;
}

.btn-side{
    background: #8E52AB;
}

.letter-white{
    color: #EDF2EB;
}

.height-form{
    height: 657px !important;
}

.border-radius-0{
    border-radius: 0px !important;
}

.text-with{
    color: white;
}