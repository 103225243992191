.align-badge {
  position: absolute;
  top: 10px;
  right: 1px !important;
  text-indent: 0;
}

.pointer {
  cursor: pointer !important;
}

.pointer:hover {
  cursor: pointer !important;
}

.icon-header {
  color: #3a3f51;
}

.icon-header:hover {
  color: #b2cef8 !important;
}

.resize-fluid {
  width: 100%;
}

@media (max-width: 767px) {
  .resize-fluid {
    width: 140px !important;
  }
}

@media (max-width: 525px) {
  .content-wrapper {
    padding-top: 67px !important;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
  }

  .aside-inner {
    top: 67px !important;
  }
}
