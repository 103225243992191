.buttons-edit{
    color: green !important;
    font-size: large !important;
}

.buttons-delete{
    color: red !important;
    font-size: large !important;
}

.position-button{
    background: transparent !important;
    border-top-width: 0px !important;
    padding-top: 0px !important;
}