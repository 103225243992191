.app-position {
  position: relative !important;
}

.app-shorcutsposition {
  position: absolute !important;
  right: -220px !important;
  bottom: -462px !important;
  height: 440px;
  width: 404px;
}

/* Color de encabezado */
.bg-trans-gradient {
  background: linear-gradient(250deg, #3e93d6, #8a75aa);
}

.m-0 {
  margin: 0 !important;
}

.text-center {
  text-align: center !important;
}

.color-white {
  color: #fff;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.opacity-80 {
  opacity: 0.8;
}

.dropdown-header {
  padding: 1.25rem 1.5rem;
}

/* contenedor de aplicaciones */
.custom-scroll,
.custom-scrollbar {
  overflow: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}
.custom-scroll::-webkit-scrollbar-track-piece,
.custom-scrollbar::-webkit-scrollbar-track-piece {
  background-color: transparent;
}
.custom-scroll::-webkit-scrollbar-thumb:vertical,
.custom-scrollbar::-webkit-scrollbar-thumb:vertical {
  background-color: #666;
}
.custom-scroll::-webkit-scrollbar,
.custom-scrollbar::-webkit-scrollbar {
  height: 4px;
  width: 4px;
}
.custom-scroll::-webkit-scrollbar-corner,
.custom-scrollbar::-webkit-scrollbar-corner {
  width: 40px;
}
.custom-scroll::-webkit-scrollbar-thumb:vertical,
.custom-scrollbar::-webkit-scrollbar-thumb:vertical {
  background-color: #666;
}

.h-100 {
  height: 100% !important;
}

/* lista de aplicaciones */
.app-list {
  margin: 0 auto;
  display: block;
  width: 21.875rem !important;
  height: 22.5rem !important;
  font-size: 0;
  padding: 0.5rem 1rem;
  text-align: center;
  padding-left: 0px !important;
}

.app-list > li {
  display: inline-block;
  text-align: center;
  padding: 0;
}

.app-list-item {
  height: 5.9375rem;
  width: 6.25rem;
  display: block;
  text-decoration: none;
  color: #666666;
  margin: 10px 2px;
  border: 1px solid transparent !important;
  outline: none;
  border-radius: 3px;
  padding-top: 1px;
  border-radius: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.app-list-item .icon-stack {
  font-size: 3.125rem;
  margin-top: 4px;
}
.app-list-item:hover {
  border: 1px solid #e3e3e3;
  padding-top: 0px;
}
.app-list-item:hover > .icon-stack {
  font-size: 3.1875rem;
}
.app-list-item:active {
  border-color: #886ab5;
  padding-top: 1px;
}
.app-list-item:active > .icon-stack {
  font-size: 3.125rem;
}

.app-list-name {
  text-align: center;
  font-size: 0.8125rem;
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap;
  overflow: hidden;
}

.icon-stack {
  position: relative;
  width: 1em;
  height: 1em;
  line-height: 1em;
  vertical-align: middle;
  text-align: center;
}

.hover-white:hover {
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(63, 91, 148, 0.03)),
    to(rgba(29, 33, 41, 0.04))
  );
  background-image: linear-gradient(
    rgba(29, 33, 41, 0.03),
    rgba(29, 33, 41, 0.04)
  );
  color: #a13ae2;
  text-decoration: none;
}

.hover-white:active {
  background: #e7e7e7;
}

/* Separador de aplicaciones */
.separation {
  bottom: 0px !important;
  height: 3px;
  width: 623px;
  background-color: #ebd3d3;
}

/* boton de agregar mas */
.position-btn {
  position: relative;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  padding-left: 2rem !important;
  padding-right: 2rem !important;
  margin-top: 1.5rem !important;
  margin-bottom: 0.5rem !important;
  width: 229px !important;
}

.potition-li {
  width: 100% !important;
}

.smallPicture {
  width: 71px;
  height: 71px;
}

.other-apps {
  width: 330px;
}

/* Agregue este atributo al elemento que necesita información sobre herramientas */
[data-tooltip] {
  position: relative;
  z-index: 2;
  cursor: pointer;
}

/* Ocultar el contenido de información sobre herramientas de forma predeterminada */
[data-tooltip]:before,
[data-tooltip]:after {
  visibility: hidden;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  pointer-events: none;
}

/* Coloque la información sobre herramientas sobre el elemento */
[data-tooltip]:before {
  position: absolute;
  bottom: -100%;
  left: 50%;
  margin-bottom: 5px;
  margin-left: -80px;
  /* margin-right: 30px; */
  padding: 7px;
  width: 160px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background-color: #000;
  background-color: hsla(0, 0%, 20%, 0.9);
  color: #fff;
  content: attr(data-tooltip);
  text-align: center;
  font-size: 14px;
  line-height: 1.2;
}

/* Hack de triángulo para que la información sobre herramientas parezca una burbuja de diálogo */
[data-tooltip]:after {
  position: absolute;
  bottom: -10%;
  left: 50%;
  margin-left: -5px;
  width: 0;
  border-top: 5px solid #000;
  border-top: 5px solid hsla(0, 0%, 20%, 0.9);
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  content: " ";
  font-size: 0;
  line-height: 0;
}

/* Mostrar contenido de información sobre herramientas al pasar el mouse por encima */
[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
  visibility: visible;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}

.top-appshortcuts {
  border-top-width: 0px !important;
}

/* aplicaciones por lista vertical*/
.app-vertical {
  font-size: 0.8125rem;
  position: fixed;
  width: 277px;
  text-align: left;
  padding-left: 4px;
}

.app-propietarie{
  position: absolute;
  font-size: 0.8125rem;
  padding-top: 19px;
  padding-left: 4px;
}

.app-suscription{
  position: absolute;
  font-size: 0.8125rem;
  padding-top: 39px;
  padding-left: 4px;
}

.app-suscription-1{
  position: absolute;
  font-size: 0.8125rem;
  padding-top: 2px;
  padding-left: 4px;
}