.h770 {
  height: 770px !important;
}

.width-size-one {
  width: 80%;
}

.width-size-two {
  width: 80%;
}

.btn-size-one {
  width: 100%;
}

.btn-size-two {
  width: 100%;
}

.select-one-modal {
  width: 389px;
}

.select-two-modal {
  width: 434px;
}

.contenedor {
  position: relative;
  display: inline-block;
  text-align: center;
}

.texto-encima {
  position: absolute;
  top: 10px;
  left: 10px;
}

.centrado {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: aliceblue;
}

.popover {
  z-index: 10060 !important ;
}

.scroll-modalbody {
  height: 450px;
  overflow-y: auto;
}

.card-row-1 {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 780px;
  width: 100%;
  height: 500px;
}

/* contenedor aplicaciones*/
.mcard {
  position: relative;
  background-color: #ffffff;
  height: 170px;
  width: 118px;
  margin: 10px;
  overflow: hidden;
  box-shadow: 0px 2px 4px 0px rgba(190, 190, 190, 0.5);
}

.mcard-image {
  /* center horizontally overflown image */
  position: absolute;
  left: -9999px;
  right: -9999px;
  margin: auto;

  height: 118px;
  width: 100%;
  background-color: #d8dcf1c4;
}

.mcard-body {
  position: absolute;
  bottom: 0;
  height: auto;
  padding: 10px 15px;
  font-family: unset;
}

.mcard-footer {
  position: absolute;
  bottom: -5px;
  height: 29px;
  padding: 1px 4px 0px 0px;
}

.mcard:hover {
  box-shadow: 7px 7px 21px #d9d9d9;
}

/* contenedor paquetes*/
.mcard-package {
  position: relative;
  background-color: #ffffff;
  height: 183px;
  width: 151px;
  margin: 10px;
  overflow: hidden;
  box-shadow: 0px 2px 4px 0px rgba(190, 190, 190, 0.5);
}

.mcard-imagepackage {
  /* center horizontally overflown image */
  position: absolute;
  left: -9999px;
  right: -9999px;
  margin: auto;

  height: 118px;
  width: 100%;
  background-color: #d8dcf1c4;
}

.mcard-bodypackage {
  position: absolute;
  bottom: 0;
  height: 61px;
  width: 100%;
  padding: 10px 15px;
  font-family: unset;
}

.mcard-footerpackage {
  position: absolute;
  bottom: -5px;
  height: 29px;
  padding: 1px 4px 0px 0px;
}

.mcard-package:hover {
  box-shadow: 7px 7px 21px #d9d9d9;
}

.thumb-package {
  width: 151px !important;
  height: 128px !important;
}

.position-check {
  padding-left: 99px !important;
}

.color-with {
  color: #ffffff;
}

.size-12 {
  font-size: 35px;
}

.color-black {
  color: black;
  font-style: unset;
}

/*Estilos de la faturación de las aplicaciones*/
.show-billingapp {
  position: relative;
  background-color: red;
  float: none;
  /* float: unset; */
  width: 100%;
  height: 100%;
  /* border-radius: 15px; */
  margin: 10px 0px;
  z-index: 0;
  border: solid 1px #b7b7b7;
  left: -4%;
  box-sizing: content-box;
}

.hide-app {
  z-index: -1;
  position: absolute;
}

.hide-billingapp {
  z-index: -1;
  position: absolute;
}

.more {
  cursor: pointer;
  color: #3333ff;
}

.width-select2 {
  width: 250px;
}

.size-20 {
  font-size: 20px;
}

.size-return {
  height: "80px" !important;
  width: "43px" !important;
}

.price-rounded {
  border-radius: 22px 22px 22px 22px;
  border: 0px solid #000000;
}

.colour-blue {
  color: #5e83ed;
}

.precious-border {
  padding-left: 5px;
  padding-right: 7px;
}
