.display-link {
  text-decoration: none !important;
}

.position-cardapp {
  position: relative;
  padding-left: 15px;
  padding-right: 15px;
  width: 222px;
}

.position-img {
  position: relative;
  left: 15px;
}

.position-empty {
  position: relative;
  left: 20px;
}
