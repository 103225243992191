.top-height {
  height: -webkit-fill-available;
}

.margin-15 {
  margin-right: -15px;
  margin-left: -15px;
}

.container-disable {
  display: initial;
}

.form-responsive {
  height: auto;
}

.hidden-cardfooter {
  background-color: transparent !important;
  border-top: hidden !important;
}

.height-header {
  height: 96px;
}

.padding-left0 {
  padding-left: 0px !important;
}

.padding-right0 {
  padding-right: 0px !important;
}

.containerLeft {
  position: fixed !important;
  height: 100%;
  z-index: 1;
}

/*Imagen de encabezado*/
.align-icon {
  text-align: center;
}

@media screen and (max-width: 767px) {
  .container-disable {
    display: none;
  }

  .form-responsive {
    height: 561px;
  }
}

.align-right {
  float: right;
}
.colour-centralize {
  background: url("/public/img/svg/pattern-1.svg") no-repeat center bottom fixed;
  height: 100%;
  overflow: hidden;
  background-color: #ffffff !important;
}
