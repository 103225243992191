.bg-edit {
  background-color: #7db5ff;
}

.bg-btnone {
  background: #4961d6;
}

.bg-btntwo {
  background-color: #54f0f7;
}

.bg-delete {
  background-color: #2f60a1;
}

.header-card {
  background-color: rgba(0, 0, 0, 0.03) !important;
}

.text-black {
  color: black;
}

.btn-headercard {
  font-size: 10px;
}

.img-left {
  float: left;
  padding: 7px;
}

.size-icondatagrid {
  font-size: x-large !important;
}

.modal-dialog {
  max-width: 1151px !important;
}

.wizard.vertical > .steps {
  display: inline;
  float: left;
  width: 19% !important;
}

.anyClass {
  height: 443px;
  overflow-y: scroll;
}

.height-modallg {
  height: 572px !important;
}

.wizard.vertical > .content {
  display: inline;
  float: left;
  margin: 0 2.5% 0.5em 2.5%;
  width: 76% !important;
}

.displayIn {
  display: initial !important;
}

.padding-left17 {
  padding-left: 17px;
}
