.shadow-lg {
    -webkit-box-shadow: 1px 10px 5px -1px rgba(0,0,0,0.75);
-moz-box-shadow: 1px 10px 5px -1px rgba(0,0,0,0.75);
box-shadow: 1px 10px 5px -1px rgba(0,0,0,0.75);
 }

.height-8{
    height: 8% !important;
}

.container-header{
    padding: 0 !important;
    align-items: center !important;
    display: flex !important;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    height: 100% !important;
}

.container-logo{
    background-image: none !important;
    height: 4px;
    height: 100% !important;
}

.page-logo-link{
    flex: 1 0 auto;
    transition: all 0.2s ease;
    align-items: center !important;
    display: flex !important;
    height: 100%;
}

.logo-position-header{
    padding-left: 169px;
}

.page-logo-text{
    margin-left: 0.5rem;
    font-weight: 300;
    font-size: 1rem;
    color: #fff;
    display: block;
    -webkit-box-flex: 1;
    flex: 1 0 auto;
    text-align: left;
}

.margin-right-05{
    margin-right: 0.5rem !important;
}

.margin-left-38{
    margin-left: 38% !important;
}

.opacity{
    opacity: 0.5;
}

.padding-0{
    padding: 0px !important;
}