.fs-xxl {
  font-size: 1.75rem !important;
}

.fs-xl {
  font-size: 1rem !important;
}

.fs-lg {
  font-size: 0.9375rem !important;
}

.fs-md {
  font-size: 0.875rem !important;
}

.fs-sm {
  font-size: 0.78125rem !important;
}

.fs-xs {
  font-size: 0.75rem !important;
}

.cursor-pointer {
  cursor: pointer;
}

.position-card-verify {
  bottom: 9px;
  right: calc(3% - -8px);
  width: 31rem;
}

.z-1000 {
  z-index: 1000;
}

@media (max-width: 525px) {
  .position-card-verify {
    width: auto;
  }
}
