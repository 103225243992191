.width-filter-md {
  width: 84%;
}

.width-sd-filter {
  width: 280px;
}

.scroll {
  max-height: 100px;
  overflow-y: auto;
}

.contenedor {
  position: relative;
  display: inline-block;
  text-align: center;
}

.text-black {
  color: black;
}
.centrado {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.align-right {
  float: right;
}

.container-icon {
  position: relative;
  display: inline-block;
  text-align: center;
}

/*Imagen de empresa*/
.update-icon:hover {
  display: inline;
  display: initial;
  background-color: rgba(110, 100, 100, 0.3);
  right: -1px;
  border-radius: 50% !important;
}

.camera {
  display: initial;
  color: rgb(0, 0, 0) !important;
  font-size: 1.2rem !important;
  position: absolute;
  width: 100%;
  right: -18px;
  bottom: 64px;
}

.grow:hover
{
-webkit-transform: scale(1.3);
-ms-transform: scale(1.3);
transform: scale(1.1);
}

.image-upload > input
{
    display: none;
}

.image-upload img
{
    width: 80px;
    cursor: pointer;
}

/*---------------------------------------------------------------*/
